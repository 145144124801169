import { Grid, List, Pagination, useMediaQuery } from "@mui/material";
import NewsGridItem from "../components/NewsGridItem";
import { useEffect, useState } from "react";
import NewsListItem from "../components/NewsListItem";

import NewsModal from "../components/NewsModal";
import SkeletonGridItem from "../components/SkeletonGridItem";
import SkeletonListItem from "../components/SkeletonListItem";
import { Helmet } from "react-helmet";
import { getCookieConsentValue } from 'react-cookie-consent';
import ReactGA from 'react-ga4';
import SeoSection from "../components/SeoSection";

const News = (props) => {
    const perPage = 36;
    const [news, setNews] = useState([]);
    const [loading, setLoading] = useState(true);
    const [showModal, setShowModal] = useState(false);
    const [selectedNews, setSelectedNews] = useState(null);
    const [totalPages, setTotalPages] = useState(1);

    useEffect(() => {
        async function fetchPosts() {
            // &categories=82
            const response = await fetch(process.env.REACT_APP_NEWS_URL + `&per_page=${perPage}&page=1`);
            const news = await response.json();
            // console.log(response.headers.get('X-WP-Total'));
            // console.log(response.headers.get('X-WP-TotalPages'));
            setTotalPages(+response.headers.get('X-WP-TotalPages'));
            setNews(news);
            setLoading(false);
        }

        fetchPosts();
    }, []);
    
    const handlePageChange = async (event, value) => {
        const anchor = (event.target.ownerDocument || document).querySelector(
            '#back-to-top-anchor',
        );
        if (anchor) {
            anchor.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }

        sendGA4PaginationClickEvent(value);
        setLoading(true);
        const response = await fetch(process.env.REACT_APP_NEWS_URL + `&per_page=${perPage}&page=${value}`);
        const news = await response.json();
        setTotalPages(+response.headers.get('X-WP-TotalPages'));
        setNews(news);
        setLoading(false);
    };

    const sendGA4NewsClickEvent = (title) => {
        try {
            if (getCookieConsentValue() === 'true') {
                ReactGA.event({
                    category: 'News',
                    action: 'click',
                    news_title: title
                });
            }
        } 
        catch (e) {}
    };

    const sendGA4PaginationClickEvent = (page) => {
        try {
            if (getCookieConsentValue() === 'true') {
                ReactGA.event({
                    category: 'News',
                    action: 'pagination',
                    page_number: page
                });
            }
        } 
        catch (e) {}
    };

    const showNews = (newsData) => {
        sendGA4NewsClickEvent(newsData.title.rendered);
        setSelectedNews(newsData);
        setShowModal(true);
    }
    const onModalClose = () => {
        setShowModal(false);
    }
    const matches = useMediaQuery('(max-width:550px)');
    let content;
    if (matches) {
        content = <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
            {
            loading ? Array.from(new Array(9)).map((item, index) => (
                <SkeletonListItem key={index} />
            )) :
            news.map((newsItem, index) => {
                return <NewsListItem newsItem={newsItem} key={index} onItemClick={showNews} />;
            })}
        </List>
    } else {
        content = <Grid container spacing={4}>
            {
                loading ? Array.from(new Array(9)).map((item, index) => (
                    <SkeletonGridItem key={index} />
                )) :
                news.map((newsItem, index) => {
                    return <NewsGridItem onItemClick={showNews} newsItem={newsItem} key={index} />;
                })
            }
        </Grid>
    }

    return <>
        <Helmet>
            <title>News sul Calcio: aggiornamenti su serie A, giocatori e risultati | BetFlagLive</title>
            <meta name="description" content="Scopri tutte le ultime notizie sul calcio, con un focus sulla serie A, i suoi giocatori e i risultati delle partite. Aggiornamenti in tempo reale sui campionati di calcio di tutto il mondo." />
        </Helmet>
        {content}
        {totalPages > 1 && <Pagination sx={{button:{backgroundColor: '#fff'}, paddingTop:2, display: 'flex', justifyContent: 'center'}} count={totalPages} color="primary" shape="rounded" onChange={handlePageChange}/>}
        <SeoSection page={'news'} />
        {selectedNews && <NewsModal show={showModal} news={selectedNews} handleClose={onModalClose} />}
    </>;
}

News.currentPage = "news";

export default News;