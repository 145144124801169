import { Card, CardActions, CardContent, CardHeader, Grid, Skeleton, Typography } from "@mui/material";

const SkeletonGridItem = () => {
    return (<Grid item xs={12} sm={6} md={4}>
        <Card variant="outlined">
            <CardHeader disableTypography sx={{ textAlign: 'center', padding: '6px'}} subheader={
            <Skeleton width={120} sx={{marginLeft:'auto', marginRight: 'auto'}} variant="text" />
            }>
            </CardHeader>
            <Skeleton variant="rectangular" height={220} />
            <CardContent>
                <Typography variant="h2">{<Skeleton />}</Typography>
                {/* <Skeleton variant="text" />
                <Skeleton variant="text" /> */}
            </CardContent>
            <CardActions disableSpacing style={{justifyContent: 'center'}}>
                <Skeleton variant="rounded" width={80} height={30} />
            </CardActions>
        </Card>
    </Grid>)
};
export default SkeletonGridItem;
