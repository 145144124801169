import Home from "./pages/Home";
import News from "./pages/News";
import NotFound from "./pages/NotFound";

const AppRoutes = [
    {
        index: true,
        element: <Home />
      },
      {
        path: '/news',
        element: <News />
      },
      {
        path: '*',
          element: <NotFound />
        },
];

export default AppRoutes;
