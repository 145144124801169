
import { Route, Routes, useSearchParams } from 'react-router-dom';
import AppRoutes from './AppRoutes';
import './App.css';
import Layout from './components/Layout';
import { Button, CssBaseline } from '@mui/material/';

import {  ThemeProvider, createTheme } from '@mui/material/styles';
import CookieConsent, { getCookieConsentValue } from 'react-cookie-consent';
import ReactGA from 'react-ga4';
import React from 'react';

export const themeOptions = {
  typography: {
    "fontFamily": `"Titillium Web", "Helvetica", "Arial", sans-serif`,
    "font-display": "swap",
    "fontSize": 14,
    "fontWeightLight": 300,
    "fontWeightRegular": 400,
    "fontWeightMedium": 500,
    button: {
      fontWeight: 600,
    }
   },
  palette: {
    mode: 'light',
    primary: {
      main: '#42aee8',
      contrastText: '#ffffff'
    },
    secondary: {
      main: '#f50057',
      contrastText: '#ffffff'
    },
    background: {
      default: '#eaebf5',
      paper: '#ffffff'
    },
  },
  shape: {
    borderRadius: 8,
  },
  components: {
    MuiAppBar: {
      styleOverrides: {
        colorPrimary: {
          backgroundColor: "#002742",
          color: "#ffffff"
        }
      }
    }
  },
  props: {
    MuiAppBar: {
      color: 'inherit',
    },
  },
};

function App() {
  const [searchParams, setSearchParams] = useSearchParams();
  let iframeBtag = "";
  try {
    const btag = searchParams.get('btag');
    if (btag) {
      iframeBtag = <iframe src={`https://www.betflag.it/?btag=${btag}`} style={{display:'none'}}></iframe>;
    }
  } catch (e) {}
  
  if (getCookieConsentValue() === 'true') {
    ReactGA.initialize('G-V9MTRR1RVP');
  }

  const theme = createTheme(themeOptions);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline/>
      <Layout>
        <Routes>
          {
            AppRoutes.map((route, index) => {
              let { element, ...rest } = route;
              return <Route key={index} {...rest} element={element} />;
            })}
        </Routes>
      </Layout>
      <CookieConsent
        location="bottom"
        buttonText="Acconsento"
        enableDeclineButton
        declineButtonText="Non acconsento"
        cookieName="CookieConsent"
        setDeclineCookie={true}
        onAccept={() => {
          window.location.reload();
        }}
        flipButtons
        ButtonComponent={Button}
        containerClasses='cookie-consent'
        contentStyle={{margin: '0 0 0 15px'}}
        // disableButtonStyles={true}
        buttonWrapperClasses='cookie-consent-button-wrapper'
        buttonStyle={{fontSize: '13px', borderRadius:'inherit!important', margin: 0}}
        declineButtonStyle={{fontSize: '13px', borderRadius:'inherit!important', margin: '0 0 0 10px'}}
        buttonClasses='cookie-consent-button'
        declineButtonClasses='deny'
        expires={150}
      >
        Questo sito utilizza cookie propri e di terzi per migliorare i servizi e analizzare le preferenze dei suoi utenti. Cliccando su "Acconsento" l'utente accetta automaticamente l'utilizzo dei cookie.
      </CookieConsent>
      {iframeBtag}
    </ThemeProvider>
    
  );
}

export default App;
