import { Box, Dialog, DialogContent, DialogTitle, IconButton, Typography } from "@mui/material"
import CloseIcon from '@mui/icons-material/Close';
import Image from "mui-image";
import logo from '../betflaglive-blu.svg';

const NewsModal = ({ news, show, handleClose }) => {
  let imageUrl = logo;
  try {
    if (news && news._embedded['wp:featuredmedia'] != null) {
      imageUrl = process.env.REACT_APP_NEWS_BASE_URL + news._embedded['wp:featuredmedia'][0].source_url;
    }
  } 
  catch (e) {
    console.log(e);
  }

  return (<Dialog open={show}>
    <DialogTitle sx={{padding: '4px 8px', backgroundColor: '#002742', display: 'flex', justifyContent: 'flex-end' }}>
    <IconButton
        style={{padding: 0, color: "#fff"}}
        onClick={handleClose}
      >
        <CloseIcon />
      </IconButton>
    </DialogTitle>
    <DialogContent style={{ position: "relative" }}>
      
      <Box sx={{ paddingTop: 2 }}>
        
        <Image
        duration={0.5}
          // src={news ?  news?.yoast_head_json?.og_image[0]?.url : logo}
          src={imageUrl}
        />
        <Typography variant="h6" component="h2" fontWeight={600} dangerouslySetInnerHTML={{__html: news?.title?.rendered}}></Typography>
        <Typography variant="body2" component="p" dangerouslySetInnerHTML={{__html: news?.content?.rendered}}></Typography>
      </Box>
    </DialogContent>
  </Dialog>)
}

export default NewsModal;