import { Avatar, ListItem, ListItemAvatar, ListItemText } from "@mui/material";
import logo from '../betflaglive-blu.svg';

const NewsListItem = (props) => {
  // const date = new Date(props.newsItem.date).toLocaleDateString('it-IT', {day: '2-digit', month: '2-digit', year:'numeric'}) + " | " + new Date(props.newsItem.date).toLocaleTimeString('it-IT', {hour: '2-digit', minute:'2-digit'})
  const date = new Date(props.newsItem.date).toLocaleDateString('it-IT', {day: '2-digit', month: '2-digit', year:'numeric'});
  let imageUrl = logo;

  try {
    if (props.newsItem._embedded['wp:featuredmedia'] != null) {
      imageUrl = process.env.REACT_APP_NEWS_BASE_URL + props.newsItem._embedded['wp:featuredmedia'][0].source_url;
    }
  } catch (e) {
      console.log(e);
  }

  return (<ListItem button alignItems="flex-start" onClick={() => props.onItemClick(props.newsItem)}>
    <ListItemAvatar>
      <Avatar alt={props.newsItem.title.rendered} src={imageUrl} />
    </ListItemAvatar>
    <ListItemText
    primaryTypographyProps={{fontWeight: "bold"}}
      primary={<div dangerouslySetInnerHTML={{ __html: props.newsItem.title.rendered }}></div>}
      secondary={
        <>
          {date}
        </>
      }
    />
  </ListItem>)
}

export default NewsListItem;