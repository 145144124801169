import { Box, Grid, Typography } from "@mui/material";
import logo from '../betflaglive-blu.svg';

const Footer = () => {
    return (
        <Box sx={{ bgcolor: "background.paper", p: 6 }}>
            <Grid container spacing={6} columns={16} justifyContent="center">
                <Grid item xs={16} sm={4} display={'flex'} justifyContent={'center'}>
                    <img src={logo} alt="logo" style={{width: '100%'}}/>
                </Grid>
                <Grid item xs={16} sm={4} display={'flex'} justifyContent={'center'}>
                    <Typography variant="p" color="text.secondary" align="center">Questo sito non rappresenta una testata giornalistica in quanto viene aggiornato senza alcuna periodicità.</Typography>
                </Grid>
                <Grid item xs={16} sm={4} display={'flex'} justifyContent={'center'}>
                    <Typography variant="p" color="text.secondary" align="center">Copyright © {new Date().getFullYear()} Betflaglive.it</Typography>
                </Grid>
            </Grid>
        </Box>
    )
};

export default Footer;