import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';
import { Link, NavLink } from 'react-router-dom';
import logo from '../betflaglive.svg';
import './DrawerNavBar.css';
import NewspaperIcon from '@mui/icons-material/Newspaper';
import HomeIcon from '@mui/icons-material/Home';
import { ListItemIcon } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const drawerWidth = 240;
const navItems = [{ text: 'Home', link: '/', icon: <HomeIcon/> }, { text: 'News', link: '/news', icon: <NewspaperIcon/> }];

function DrawerNavBar(props) {
    var bfLiveLogo = logo;
    // if date between 1/12 and 06/01
    var today = new Date();
    var dd = today.getDate();
    var mm = today.getMonth() + 1; //January is 0!
    if ((mm === 12 && dd >= 1) || (mm === 1 && dd <= 6)) {
        bfLiveLogo = "https://cdn.betflag.it/site/img/betflaglive.xmas.png";
    }

    const { window } = props;
    const [mobileOpen, setMobileOpen] = React.useState(false);

    const handleDrawerToggle = () => {
        setMobileOpen((prevState) => !prevState);
    };

    const drawer = (
        <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center' }}>
            <Box sx={{ display: 'flex', flexGrow: 1 }} justifyContent='flex-end' alignItems="center">
                {/* <Link to="/" className="logo-link">
                    <img className="logo" src={logo} alt="Betflaglive.it" title="Betflaglive.it"/>
                </Link> */}
                <IconButton sx={{color: '#fff'}}>
                    <CloseIcon />
                </IconButton>
            </Box>
            <Divider />
            <List >
                {navItems.map((item,index) => (
                    <ListItem key={index} disablePadding >
                        <ListItemButton  component={NavLink} to={item.link}>
                            <ListItemIcon sx={{color: '#fff'}}>{item.icon}</ListItemIcon>
                            <ListItemText primary={item.text} sx={{color: '#fff'}}/>
                        </ListItemButton>
                    </ListItem>
                ))}
            </List>
        </Box>
    );

    const container = window !== undefined ? () => window().document.body : undefined;

    return (
        <Box sx={{ display: 'flex' }}>
            <CssBaseline />
            <AppBar component="nav">
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        edge="start"
                        onClick={handleDrawerToggle}
                        sx={{ color: '#fff', mr: 2, display: { sm: 'none' } }}
                    >
                        <MenuIcon />
                    </IconButton>
                    {/* <Typography
                        variant="h6"
                        component="div"
                        sx={{ flexGrow: 1, display: { xs: 'none', sm: 'block' } }}
                    >
                        MUI
                    </Typography> */}
                    <Box sx={{ display: 'flex', flexGrow: 1 }} alignItems="center">
                        <Link to="/" className="logo-link">
                            <img className="logo" src={bfLiveLogo} alt="Betflaglive.it" title="Betflaglive.it"/>
                        </Link>
                    </Box>
                    <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
                        {navItems.map((item, index) => (
                            <Button key={index} sx={{ color: '#fff' }} component={NavLink} to={item.link}>
                                {item.text}
                            </Button>
                        ))}
                    </Box>
                </Toolbar>
            </AppBar>
            <nav>
                <Drawer
                    container={container}
                    variant="temporary"
                    open={mobileOpen}
                    onClose={handleDrawerToggle}
                    ModalProps={{
                        keepMounted: true, // Better open performance on mobile.
                    }}
                    sx={{
                        display: { xs: 'block', sm: 'none' },
                        '& .MuiDrawer-paper': { 
                            boxSizing: 'border-box', width: drawerWidth,
                            backgroundColor: '#002742',
                            color: '#fff'
                        },
                    }}
                >
                    {drawer}
                </Drawer>
            </nav>
        </Box>
    );
}

export default DrawerNavBar;
